import { graphql, Link, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import HeaderSmall from "./headerSmall"

export const stdStarImage = graphql`
  fragment stdStarImage on File {
    childImageSharp {
      fixed(width: 150, height: 95) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`

const GuideStarComponent = ({Section,Provider,Product,Stars,Year}) => (
  <StaticQuery
    query={graphql`
      query {
        bil20195: file(relativePath: { eq: "2019-bil-defaqto-5.png" }) {
          ...stdStarImage
        },
        bil20194: file(relativePath: { eq: "2019-bil-defaqto-4.png" }) {
          ...stdStarImage
        },
        bil20193: file(relativePath: { eq: "2019-bil-defaqto-3.png" }) {
          ...stdStarImage
        }
        hus20195: file(relativePath: { eq: "2019-hus-defaqto-5.png" }) {
          ...stdStarImage
        },
        hus20194: file(relativePath: { eq: "2019-hus-defaqto-4.png" }) {
          ...stdStarImage
        },
        hus20193: file(relativePath: { eq: "2019-hus-defaqto-3.png" }) {
          ...stdStarImage
        }
        reise20195: file(relativePath: { eq: "2019-reise-defaqto-5.png" }) {
          ...stdStarImage
        },
        reise20194: file(relativePath: { eq: "2019-reise-defaqto-4.png" }) {
          ...stdStarImage
        },
        reise20193: file(relativePath: { eq: "2019-reise-defaqto-3.png" }) {
          ...stdStarImage
        }
        innbo20195: file(relativePath: { eq: "2019-innbo-defaqto-5.png" }) {
          ...stdStarImage
        },
        innbo20194: file(relativePath: { eq: "2019-innbo-defaqto-4.png" }) {
          ...stdStarImage
        },
        innbo20193: file(relativePath: { eq: "2019-innbo-defaqto-3.png" }) {
          ...stdStarImage
        }
      }
    `}
    render={data => (
      <>
        <div className="moto-widget moto-widget-row__column moto-cell col-sm-4 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
          <div className="moto-widget moto-widget-text moto-preset-default moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="sama" data-visible-on="mobile-v" data-animation>
            <div className="moto-widget-text-content moto-widget-text-editable">
              <div className="moto-text_normal" style={{textAlign: 'center'}}>
                <span className="moto-content-image-plugin-wrapper moto-spacing-top-zero moto-spacing-right-zero moto-spacing-bottom-zero moto-spacing-left-zero">
                  <span className="moto-content-image-container">
                    <Img className="moto-content-image" alt={Provider + " " + Product + " -  " + Stars.toString() + " Defaqto stjerner"} fixed={
                      (Stars === "5" && Section === "bil" && Year === "2019" && data.bil20195.childImageSharp.fixed) ||
                      (Stars === "4" && Section === "bil" && Year === "2019" && data.bil20194.childImageSharp.fixed) ||
                      (Stars === "3" && Section === "bil" && Year === "2019" && data.bil20193.childImageSharp.fixed) ||
                      (Stars === "5" && Section === "hus" && Year === "2019" && data.hus20195.childImageSharp.fixed) ||
                      (Stars === "4" && Section === "hus" && Year === "2019" && data.hus20194.childImageSharp.fixed) ||
                      (Stars === "3" && Section === "hus" && Year === "2019" && data.hus20193.childImageSharp.fixed) ||
                      (Stars === "5" && Section === "reise" && Year === "2019" && data.reise20195.childImageSharp.fixed) ||
                      (Stars === "4" && Section === "reise" && Year === "2019" && data.reise20194.childImageSharp.fixed) ||
                      (Stars === "3" && Section === "reise" && Year === "2019" && data.reise20193.childImageSharp.fixed) ||
                      (Stars === "5" && Section === "innbo" && Year === "2019" && data.innbo20195.childImageSharp.fixed) ||
                      (Stars === "4" && Section === "innbo" && Year === "2019" && data.innbo20194.childImageSharp.fixed) ||
                      (Stars === "3" && Section === "innbo" && Year === "2019" && data.innbo20193.childImageSharp.fixed)
                    }/>
                  </span>
                </span>
              </div>
              <div className="moto-text_normal" style={{textAlign: 'center'}}>
                <div style={{textAlign: 'center'}} className="moto-color2_1">
                  <Link style={{color:'gray',fontSize:'120%'}} to={"/"+Section+"/"+slugifier(Provider)+"/"+slugifier(Product)}><strong>{Provider}: {Product}</strong></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )}
    />
)

export default GuideStarComponent

GuideStarComponent.propTypes = {
  Section: PropTypes.string,
  Provider: PropTypes.string,
  Product: PropTypes.string,
  Stars: PropTypes.number,
  Year: PropTypes.string
}

GuideStarComponent.defaultProps = {
  Provider: ``,
  Product: ``,
  Stars: ``,
  Section: ``,
  Year: ``,
}

function slugifier(input) {

  let slugString = input;

  return slugString
  //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}