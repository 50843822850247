import React from "react"
import { Link,graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSmall from "../components/headerSmall"
import GuideStarComponent from "../components/guideStarComponent"
import ComparisonTable from "../components/comparisonTable"
import '../css/forsikringstilbyder-detaljer.css'

export const query = graphql`
query ($provider: String){
    allBilforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
      edges {
        node {
          Provider
          Product
          Defaqto_Stjerne
        }
      }
    }
    
    allBarneforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
        edges {
            node {
                Provider
                Product
                Defaqto_Stjerne
            }
        }
    }
    
    allHusforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
      edges {
        node {
          Provider
          Product
          Defaqto_Stjerne
        }
      }
    }
 
    allReiseforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
      edges {
        node {
          Provider
          Product
          Defaqto_Stjerne
        }
      }
    }

    allInnboforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
      edges {
        node {
          Provider
          Product
          Defaqto_Stjerne
        }
      }
    }

    allTurbatforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
        edges {
            node {
                Provider
                Product
                Defaqto_Stjerne
            }
        }
    }

    allFritidsbatforsikring2020Csv(filter: {Provider: {eq: $provider}},sort: {fields: Defaqto_Stjerne, order: DESC}) {
        edges {
            node {
                Provider
                Product
                Defaqto_Stjerne
            }
        }
    }

    bil: file(relativePath: { eq: "bilforsikring_5d191691ef49f.png" }) {
        childImageSharp{
            fixed(width:16,height:16){
                src
            }
        }
    },
    hus: file(relativePath: { eq: "husforsikring_5d191691ef49f.png" }) {
        childImageSharp{
            fixed(width:16,height:16){
                src
            }
        }
    },
    innbo: file(relativePath: { eq: "innboforsikring_5d191692676d3.png" }) {
        childImageSharp{
            fixed(width:16,height:16){
                src
            }
        }
    },
    reise: file(relativePath: { eq: "reiseforsikring_5d1916926a533.png" }) {
        childImageSharp{
            fixed(width:16,height:16){
                src
            }
        }
    },
    bat: file(relativePath: { eq: "baatforsikring_5d19169158fa0.png" }) {
        childImageSharp{
            fixed(width:16,height:16){
                src
            }
        }
    },
    barn: file(relativePath: { eq: "barneforsikring_5d1916915a0f1.png" }) {
        childImageSharp{
            fixed(width:16,height:16){
                src
            }
        }
    }
  }
  `

export default({pageContext,data}) => {

  const d = new Date()
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  let year = d.getFullYear()

  if(month.length < 2)
    month = '0' + month

  if(day.length < 2)
    day = '0' + day

  const date = [year, month, day].join('-')

  const insurerData = {
    'Gjensidige': {
      'identifier': '',
      'name': '',
      'url': '',
      'logo': '',
      'reviewAggregateStars': ''
    }
  }

  const provider = pageContext.provider


  return (
    <Layout>
      <SEO
        title={"Vurdering av " + provider+ " sine forsikringer | Vilkår og dekninger"}
        description={"Hvor gode er forsikringene til " + provider+ " og hvor god dekning gir de?" + " Faktabasert sammenligning med hensyn på vilkår og dekninger av forsikringer tilbudt av "+provider+" sett opp mot andre forsikringsselskaper."}
        schema={`
              {
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": [{
                "@type": "ListItem",
                "position": 1,
                "name": "Bestforsikring.no",
                "item": "https://bestforsikring.no"
              },
              {
                "@type": "ListItem",
                "position": 2,
                "name": "Vurdering av `+ provider+`",
                "item": "https://bestforsikring.no/`+slugifier(provider)+`"
              }]
            }
         `}
      />

      {/*providerSchema={`
          {
            "@context": "https://schema.org/",
            "@type": "Review",
            "author": {
              "@type": "Organization",
              "name": "Bestforsikring.no",
              "url": "https://bestforsikring.no",
              "identifier": "https://bestforsikring.no"
            },
            "itemReviewed": {
              "@type": "Organization",
              "identifier": "NO89234243324234423",
              "name": "Gjensidige Forsikring",
              "logo": "https://www.gjensidige.no/_/image/e3933040-780d-4322-bf1d-89c68ffb0bf7:18e676f619dd0bbdd29808c8da95b6dbc2a5498e/block-149-40/gjensidige_logo.svg",
              "url": "https://gjensidige.no",
              "sameAs": [
                "https://www.instagram.com/gjensidige/",
                "https://www.facebook.com/GjensidigeNorge/"
              ]
            },
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "worstRating": "1",
              "bestRating": "5",
              "reviewAspect": "Insurance products"
            },
            "datePublished": "`+date+`T00:00:00.000Z",
            "publisher": {
              "@type": "Organization",
              "name": "Bestforsikring.no",
              "url": "https://bestforsikring.no",
              "identifier": "https://bestforsikring.no"
            }
          }
      `}*/}

      <HeaderSmall/>
      <div className="moto-widget moto-widget-row row-fixed moto-bg-color5_5 moto-spacing-top-small moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-grid-type="sm" data-widget="row" data-spacing="lala" style={{}} data-bg-position="left top" data-draggable-disabled>
        <div className="container-fluid">
          <div className="row" data-container="container">
            <div className="moto-widget moto-widget-row__column moto-cell col-sm-12 moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-auto moto-spacing-left-auto" style={{}} data-widget="row.column" data-container="container" data-spacing="aaaa" data-bg-position="left top">
              <div className="krPW moto-widget moto-widget-text moto-preset-default moto-spacing-top-auto moto-spacing-right-auto moto-spacing-bottom-medium moto-spacing-left-auto" data-widget="text" data-preset="default" data-spacing="aama" data-visible-on="mobile-v" data-animation data-draggable-disabled>
                <h3 style={{fontSize:'110%',paddingBottom:20}} className="moto-text_system_7"><Link to={"#"}>Forsikringsselskaper</Link> > <> </>
                  <Link to={"/"+slugifier(provider)}>{provider}</Link>
                </h3>
                <h1>Vurdering av forsikringene til {provider}</h1>

                <p>
                  Hvor gode vilkår og hvor god dekning gir forsikringene fra forsikringsselskapet {provider}? Her følger en faktabasert ekspertvurdering av alle produkter fra dette forsikringsselskapet for følgende typer forsikringer:
                </p>

                <div className="moto-spacing-bottom-medium moto-spacing-top-medium">

                  <ul>
                    <li style={{before:''}}>
                      <a href="#bil">
                      <span
                        style={{marginTop:'-5px',display:'inline-block'}}
                        className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                        className="moto-content-image-container"><img className="moto-content-image"
                                                                      alt={"Oversikt over bilforsikring fra " + provider}
                                                                      title={"Oversikt over bilforsikring fra " + provider}
                                                                      width={16} height={16}
                                                                      src={data.bil.childImageSharp.fixed.src}/></span></span>
                        Vurdering av bilforsikringer fra {provider}</a>
                    </li>


                    <li>
                      <a href="#hus">
                    <span
                      className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                      className="moto-content-image-container"><img className="moto-content-image"
                                                                    alt={"Oversikt over husforsikringer fra " + provider}
                                                                    title={"Oversikt over husforsikringer fra " + provider}
                                                                    width={16} height={16}
                                                                    src={data.hus.childImageSharp.fixed.src}/></span></span>
                        Vurdering av husforsikringer fra {provider}</a>
                    </li>

                    <li>
                      <a href="#innbo">
                    <span
                      className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                      className="moto-content-image-container"><img className="moto-content-image"
                                                                    alt={"Oversikt over innboforsikringer fra " + provider}
                                                                    title={"Oversikt over innboforsikringer fra " + provider}
                                                                    width={16} height={16}
                                                                    src={data.innbo.childImageSharp.fixed.src}/></span></span>
                        Vurdering av innboforsikringer fra {provider}</a>
                    </li>

                    <li>
                      <a href="#reise">
                    <span
                      className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                      className="moto-content-image-container"><img className="moto-content-image"
                                                                    alt={"Oversikt over reiseforsikringer fra " + provider}
                                                                    title={"Oversikt over reiseforsikringer fra " + provider}
                                                                    width={16} height={16}
                                                                    src={data.reise.childImageSharp.fixed.src}/></span></span>
                        Vurdering av reiseforsikringer fra {provider}</a>
                    </li>

                    <li>
                      <a href="#barn">
                    <span
                      className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                      className="moto-content-image-container"><img className="moto-content-image"
                                                                    alt={"Oversikt over barneforsikringer fra " + provider}
                                                                    title={"Oversikt over barneforsikringer fra " + provider}
                                                                    width={16} height={16}
                                                                    src={data.barn.childImageSharp.fixed.src}/></span></span>
                        Vurdering av reiseforsikringer fra {provider}</a>
                    </li>

                    <li>
                      <a href="#turbat">
                    <span
                      className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                      className="moto-content-image-container"><img className="moto-content-image"
                                                                    alt={"Oversikt over turbåtforsikringer fra " + provider}
                                                                    title={"Oversikt over turbåtforsikringer fra " + provider}
                                                                    width={16} height={16}
                                                                    src={data.bat.childImageSharp.fixed.src}/></span></span>
                        Vurdering av båtforsikringer fra {provider} (turbåt)</a>
                    </li>

                    <li>
                      <a href="#turbat">
                    <span
                      className="moto-content-image-plugin-wrapper moto-spacing-top-small moto-spacing-right-small moto-spacing-bottom-zero moto-spacing-left-zero"><span
                      className="moto-content-image-container"><img className="moto-content-image"
                                                                    alt={"Oversikt over fritidsbåtforsikringer fra " + provider}
                                                                    title={"Oversikt over fritidsbåtforsikringer fra " + provider}
                                                                    width={16} height={16}
                                                                    src={data.bat.childImageSharp.fixed.src}/></span></span>
                        Vurdering av båtforsikringer fra {provider} (fritidsbåt)</a>
                    </li>

                  </ul>

                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"bil"}></a>
                  <h2>

                    Vurdering av bilforsikringer fra {provider}</h2>
                  <p>
                    Hvor gode er {provider} sine bilforsikringer? Her er en vurdering av bilforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"bil"} objList={data.allBilforsikring2020Csv}/>
                  </div>
                  {/*data.allBilforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"bil"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                  <br/>
                  <p><small>*Dekningstypene Ansvar og Delkasko er ikke vurdert da de er grunnleggende dekninger hvor forskjellene fra selskap til selskap er små.</small></p>
                  <br/>
                  <p><Link className="krLink" to={"/bil/sammenlign"}>Sammenlign flere bilforsikringer &raquo;</Link></p>
                  <br/>
                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"hus"}></a>
                  <h2>Vurdering av husforsikringer fra {provider}</h2>
                  <p>
                    Hvor gode er {provider} sine husforsikringer? Her er en vurdering av husforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"hus"} objList={data.allHusforsikring2020Csv}/>
                  </div>
                  <br/>
                  <p><Link className="krLink" to={"/hus/sammenlign"}>Sammenlign flere husforsikringer &raquo;</Link></p>
                  <br/>
                  {/*data.allHusforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"hus"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"innbo"}></a>
                  <h2>Vurdering av innboforsikringer fra {provider}</h2>
                  <p>
                    Hvor gode er {provider} sine innboforsikringer? Her er en vurdering av innboforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"innbo"} objList={data.allInnboforsikring2020Csv}/>
                  </div>
                  {/*data.allInnboforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"innbo"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                  <br/>
                  <p><Link className="krLink" to={"/innbo/sammenlign"}>Sammenlign flere innboforsikringer &raquo;</Link></p>
                  <br/>
                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"reise"}></a>
                  <h2>Vurdering av reiseforsikringer fra {provider}</h2>
                  <p>
                    Hvor gode er {provider} sine reiseforsikringer? Her er en vurdering av reiseforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"reise"} objList={data.allReiseforsikring2020Csv}/>
                  </div>

                  {/*data.allReiseforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"reise"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                  <br/>
                  <p><Link className="krLink" to={"/reise/sammenlign"}>Sammenlign flere reiseforsikringer &raquo;</Link></p>
                  <br/>
                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"barn"}></a>
                  <h2>Vurdering av barneforsikringer fra {provider}</h2>
                  <p>
                    Hvor gode er {provider} sine barneforsikringer? Her er en vurdering av barneforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"reise"} objList={data.allBarneforsikring2020Csv}/>
                  </div>

                  {/*data.allReiseforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"reise"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                  <br/>
                  <p><Link className="krLink" to={"/barn/sammenlign"}>Sammenlign flere barneforsikringer &raquo;</Link></p>
                  <br/>
                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"turbat"}></a>
                  <h2>Vurdering av båtforsikringer fra {provider} (turbåt)</h2>
                  <p>
                    Hvor gode er {provider} sine forsikringer for båtturer? Her er en vurdering av turbåtforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"reise"} objList={data.allTurbatforsikring2020Csv}/>
                  </div>

                  {/*data.allReiseforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"reise"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                  <br/>
                  <p><Link className="krLink" to={"/bat/turbat/sammenlign"}>Sammenlign flere båtforsikringer (turbåt) &raquo;</Link></p>
                  <br/>
                </div>

                <div className="moto-spacing-top-medium">
                  <a name={"turbat"}></a>
                  <h2>Vurdering av båtforsikringer fra {provider} (fritidsbåt)</h2>
                  <p>
                    Hvor gode er {provider} sine forsikringer for båter til fritidsformål? Her er en vurdering av fritidsbåtforsikringene til {provider}, rangert etter hvor gode vilkår og dekning de gir:
                  </p>
                  <br/>
                  <div className="krTDiv moto-text_normal" style={{marginLeft: 'auto',marginRight: 'auto'}}>
                    <ComparisonTable showProvider={true} showHeaders={true} siteSection={"reise"} objList={data.allFritidsbatforsikring2020Csv}/>
                  </div>

                  {/*data.allReiseforsikring2020Csv.edges.map(({node:forsikring,i}) => (
                  <GuideStarComponent key={i} Section={"reise"} Year={"2020"} Product={forsikring.Product} Provider={forsikring.Provider} Stars={forsikring.Defaqto_Stjerne}/>
                ))*/}
                  <br/>
                  <p><Link className="krLink" to={"/bat/fritidsbat/sammenlign"}>Sammenlign flere båtforsikringer (fritidsbåt) &raquo;</Link></p>
                  <br/>
                </div>

                <div className="moto-spacing-top-medium">
                  <h2>Efaringer med {provider} som forsikringsselskap</h2>
                  <p>
                    Vi har per i dag ikke fått inn noen kundeerfaringer for forsikringsselskapet {provider}.
                  </p>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

function slugifier(input) {

  let slugString = input;

  return slugString
  //.toLowerCase()
    .replace(/æ/g,'ae')
    .replace(/ø/g,'o')
    .replace(/å/g,'aa')
    .replace(/&/g,'og')
    .replace(/[^a-zA-Z0-9\/]+/g, '-')
    .replace(/\/-/g,'/')
    .slice(0, 96)                                             // Setting max length to 96
    .replace(/-$/g,'')
}